<template>
  <div class="space-y-4">
    <asom-card title="Upload Roster CSV">
      <div class="space-y-4">
        <asom-alert v-if="rosterData.error"
          variant="error"
          :error-message="rosterData.error"
        />
        <asom-form-field label="Add CSV File">
          <asom-input-text
            type="file"
            id="rosterFileInput"
            ref="rosterFileInput"
            accept=".csv,.xls,.xlsm"
            @change="onRosterFileUpload"
          />
        </asom-form-field>
        <div class="flex justify-end">
          <asom-button
            :disabled="rosterData.file == null || rosterData.isSubmitting"
            text="Upload"
            @click="uploadRosterData"
          />
        </div>
      </div>
    </asom-card>
    <asom-card title="Upload Leave CSV">
      <div class="space-y-4">
        <asom-alert v-if="leaveData.error"
          variant="error"
          :error-message="leaveData.error"
        />
        <asom-form-field label="Add CSV File">
          <asom-input-text
            type="file"
            id="leaveFileInput"
            ref="leaveFileInput"
            accept=".csv,.xls,.xlsm"
            @change="onLeaveFileUpload"
          />
        </asom-form-field>
        <div class="flex justify-end">
          <asom-button
            :disabled="leaveData.file == null || leaveData.isSubmitting"
            text="Upload"
            @click="uploadLeaveData"
          />
        </div>
      </div>
    </asom-card>
    <asom-card title="Upload OT CSV">
      <div class="space-y-4">
        <asom-alert v-if="otData.error"
          variant="error"
          :error-message="otData.error"
        />
        <asom-form-field label="Add CSV File">
          <asom-input-text
            type="file"
            id="otFileInput"
            ref="otFileInput"
            accept=".csv,.xls,.xlsm"
            @change="onOtFileUpload"
          />
        </asom-form-field>
        <div class="flex justify-end">
          <asom-button
            :disabled="otData.file == null || otData.isSubmitting"
            text="Upload"
            @click="uploadOtData"
          />
        </div>
      </div>
    </asom-card>
    <asom-card title="Upload Resignee CSV">
      <div class="space-y-4">
        <asom-alert v-if="resigneeData.error"
          variant="error"
          :error-message="resigneeData.error"
        />
        <asom-form-field label="Add CSV File">
          <asom-input-text
            type="file"
            id="resigneeFileInput"
            ref="resigneeFileInput"
            accept=".csv,.xls,.xlsm"
            @change="onResigneeFileUpload"
          />
        </asom-form-field>
        <div class="flex justify-end">
          <asom-button
            :disabled="resigneeData.file == null || resigneeData.isSubmitting"
            text="Upload"
            @click="uploadResigneeData"
          />
        </div>
      </div>
    </asom-card>
  </div>
</template>

<script>
import { uploadSapLeave, uploadSapOt, uploadSapRoster, uploadSapResignee } from '../../services/file.service';
export default {
  data() {
    return {
      rosterData: {
        isSubmitting: false,
        error: null,
        file: null,
      },
      leaveData: {
        isSubmitting: false,
        error: null,
        file: null,
      },
      otData: {
        isSubmitting: false,
        error: null,
        file: null,
      },
      resigneeData: {
        isSubmitting: false,
        error: null,
        file: null,
      },
    }
  },
  methods: {
    onRosterFileUpload(event) {
      this.rosterData.file = event.target.files[0];
    },
    onLeaveFileUpload(event) {
      this.leaveData.file = event.target.files[0];
    },
    onOtFileUpload(event) {
      this.otData.file = event.target.files[0];
    },
    onResigneeFileUpload(event) {
      this.resigneeData.file = event.target.files[0];
    },
    async uploadRosterData() {
      this.rosterData.error = null;
      this.rosterData.isSubmitting = true;
      
      if (!this.rosterData.file) {
        this.rosterData.error = "Please select a file to upload";
      } else {
        const resp = await uploadSapRoster(this.rosterData.file);
        if (!resp.success) {
          this.rosterData.error = resp.payload;
          this.$scrollTop();
        } else {
          this.rosterData.file = null;
          this.$refs.rosterFileInput.value = null;
          alert("Upload successful");
        }
      }

      this.rosterData.isSubmitting = false;
    },
    async uploadLeaveData() {
      this.leaveData.error = null;
      this.leaveData.isSubmitting = true;
      
      if (!this.leaveData.file) {
        this.leaveData.error = "Please select a file to upload";
      } else {
        const resp = await uploadSapLeave(this.leaveData.file);
        if (!resp.success) {
          this.leaveData.error = resp.payload;
          this.$scrollTop();
        } else {
          this.leaveData.file = null;
          this.$refs.leaveFileInput.value = null;
          alert("Upload successful");
        }
      }

      this.leaveData.isSubmitting = false;
    },
    async uploadOtData() {
      this.otData.error = null;
      this.otData.isSubmitting = true;
      
      if (!this.otData.file) {
        this.otData.error = "Please select a file to upload";
      } else {
        const resp = await uploadSapOt(this.otData.file);
        if (!resp.success) {
          this.otData.error = resp.payload;
          this.$scrollTop();
        } else {
          this.otData.file = null;
          this.$refs.otFileInput.value = null;
          alert("Upload successful");
        }
      }

      this.otData.isSubmitting = false;
    },
    
    async uploadResigneeData() {
      this.resigneeData.error = null;
      this.resigneeData.isSubmitting = true;
      
      if (!this.resigneeData.file) {
        this.resigneeData.error = "Please select a file to upload";
      } else {
        const resp = await uploadSapResignee(this.resigneeData.file);
        if (!resp.success) {
          this.resigneeData.error = resp.payload;
          this.$scrollTop();
        } else {
          this.resigneeData.file = null;
          this.$refs.resigneeFileInput.value = null;
          alert("Upload successful");
        }
      }

      this.otData.isSubmitting = false;
    },
  }
}
</script>

<style>

</style>